import { useEffect, useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, ListGroup, Col } from 'reactstrap';
import BreadCrumb from "../Components/BreadCrumb";
import { animateCSS } from "../helpers";
import ContactForm from "./ContactForm";
import EnquiryForm from "./EnquiryForm";
import { CONTACT_INFO } from "../data";
import { useLocation } from "react-router-dom";

const Contact = ({ isSmall }) => {

    const [activeTab, setActiveTab] = useState(1)

    const location = useLocation()

    useEffect(() => {
        animateCSS('.breadcrumbWrapper', 'fadeInDown').then(() => {
            animateCSS('#animateText', 'fadeInDown').then(
                () => document.querySelector('.animateBox').classList.remove('hidden')
            );
            document.querySelector('#animateText').classList.remove('hidden');
        })
        document.querySelector('.breadcrumbWrapper').classList.remove('hidden');

        if (location.hash === "#enquiry")
            setActiveTab(2)
    }, [location.hash]);

    return (
        <>
            <div className="container-fluid">
                <div className="row my-5 py-5">
                    <div className="col-12 col-lg-10 offset-lg-1">
                        <BreadCrumb isSmall={isSmall} links={[{ text: "Get in Touch" }]} />
                        <div className="hidden animate__faster" id="animateText">
                            <h3 className="text-primary">
                                Get in Touch
                            </h3>
                            <p className="mb-5">
                            </p>
                        </div>
                        <div className="hidden animateBox">
                            <Nav tabs>
                                <NavItem className={`border-bottom border-3 w-50 text-center ${1 === activeTab ? "border-primary" : ""}`}>
                                    <NavLink
                                        className={`p-3 mb-1 mx-1 rounded ${1 === activeTab ? "bg-primary text-white" : "bg-white text-nav-unactive"}`}
                                        onClick={() => setActiveTab(1)}
                                    >
                                        Contact Us
                                    </NavLink>
                                </NavItem>
                                <NavItem className={`border-bottom border-3 w-50 text-center ${2 === activeTab ? "border-primary" : ""}`}>
                                    <NavLink
                                        className={`p-3 mb-1 mx-1 rounded ${2 === activeTab ? "bg-primary text-white" : "bg-white text-nav-unactive"}`}
                                        onClick={() => setActiveTab(2)}
                                    >
                                        Enquiry
                                    </NavLink>
                                </NavItem>
                            </Nav>
                            <TabContent className="py-4 py-lg-5" activeTab={activeTab}>
                                <TabPane tabId={1}>
                                    <div className="px-3 py-5 p-lg-5" style={{ background: '#F3F3F3' }}>
                                        <h5 className="text-info mb-3">Get in touch with us!</h5>
                                        <p className="text-nav-unactive mb-4">Just say hello or drop us a line. You can manualy send us email on saless@parasavnath.com</p>
                                        <ContactForm isSmall={isSmall} />
                                    </div>
                                    <Row className="mt-5">
                                        <Col sm="12" lg="5" className='pt-3 offset-lg-1'>
                                            <p className="h5 mb-3 mt-0 mt-lg-0 text-info">
                                                Company Details
                                            </p>
                                            <ListGroup className="mt-2">
                                                <p className='mb-1 font-weight-bold widen'><u>GST</u></p>

                                                <p className='mb-0 font-weight-light'>{CONTACT_INFO.gst}</p>
                                            </ListGroup>
                                            <ListGroup className="mt-2">
                                                <p className='mb-1 font-weight-bold widen'><u>PAN</u></p>
                                                <p className='mb-0 font-weight-light'>{CONTACT_INFO.pan}</p>
                                            </ListGroup>
                                            {/* <ListGroup className="mt-2">
                                                <p className='mb-1 font-weight-bold widen'><u>ISO NO.</u></p>
                                                <p className='mb-0 font-weight-light'>{CONTACT_INFO.iso}</p>
                                            </ListGroup> */}
                                        </Col>
                                        {/* <Col sm="12" lg="4" className='pt-3'>
                                            <p className="h5 mb-3 mt-0 mt-lg-0 text-info">
                                                Bank Details
                                            </p>
                                            <ListGroup className="mt-2">
                                                <p className='mb-0 font-weight-bold'>A/C Name</p>
                                                <p className='mb-0 font-weight-light'>Parasavnath Trading Co.</p>
                                                <p className='mb-0 font-weight-bold'>A/C Number</p>
                                                <p className='mb-0 font-weight-light'>Parasavnath Trading Co.</p>
                                                <p className='mb-0 font-weight-bold'>Bank Name</p>
                                                <p className='mb-0 font-weight-light'>State Bank of India</p>
                                                <p className='mb-0 font-weight-bold'>Bank Branch</p>
                                                <p className='mb-0 font-weight-light'>Rohini, Delhi</p>
                                                <p className='mb-0 font-weight-bold'>IFSC Code</p>
                                                <p className='mb-0 font-weight-light'>SBIN0015841</p>
                                            </ListGroup>
                                        </Col> */}
                                        <Col sm="12" lg="5" className='pt-3 offset-lg-1'>
                                            <p className="h5 mb-3 mt-0 mt-lg-0 text-info">
                                                Contact Information
                                            </p>
                                            <ListGroup className="mt-2 mb-3">
                                                <p className='mb-1 font-weight-bold widen'><u>OFFICE</u></p>
                                                <a href={CONTACT_INFO.address_href} target="_blank" className='text-dark'>
                                                    {CONTACT_INFO.address.map((data, i) =>
                                                        <p key={i} className='mb-0 font-weight-light'>{data}</p>
                                                    )}</a>
                                            </ListGroup>
                                            <ListGroup className="mt-1">
                                                <p className='mb-1 font-weight-bold widen'><u>PHONE</u></p>
                                                <a href={CONTACT_INFO.whatsapp_href} target="_blank" className='text-dark mb-3 font-weight-light'>{CONTACT_INFO.phone}</a>
                                            </ListGroup>
                                            <ListGroup className="mt-1">
                                                <p className='mb-1 font-weight-bold widen'><u>EMAIL</u></p>
                                                <a href={`mailto:parasavnath.com`} className='text-dark mb-3 font-weight-light'>{CONTACT_INFO.email}</a>
                                            </ListGroup>
                                        </Col>
                                    </Row>
                                </TabPane>
                                <TabPane tabId={2} className="px-3 py-5 p-lg-5" style={{ background: '#F3F3F3' }}>
                                    <h5 className="text-info mb-3">Enquiry Form</h5>
                                    <p className="text-nav-unactive mb-4">Select a product from the list below and send your enquiry to us. We will provide our best quotes possible!</p>
                                    <EnquiryForm isSmall={isSmall} />
                                </TabPane>
                            </TabContent>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact;