import { Link, useNavigate } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Button } from "reactstrap";


const BreadCrumb = ({ isSmall, links = [] }) => {

    const navigate = useNavigate();
    const lenLinks = links.length;

    if (isSmall)
        return (
            <Button
                className="mb-4 pl-0 pr-3 breadcrumbWrapper hidden animate__fastest animate__delay-1s"
                color="transparent"
                onClick={() => lenLinks > 1 ? navigate(links[0].to) : navigate("/")}
                style={{
                    marginLeft: "-0.5rem"
                }}
            >
                <svg width="35" height="35" viewBox="0 0 24 24" fill="#0F61AB">
                    <path d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
                </svg>
            </Button>
        )

    return (
        <Breadcrumb className="mb-5 bg-white h5 breadcrumbWrapper hidden animate__fastest  animate__delay-1s">
            <BreadcrumbItem><Link to="/" replace>Home</Link></BreadcrumbItem>
            {lenLinks > 1 ? <><BreadcrumbItem><Link to={links[0].to} replace>{links[0].text}</Link></BreadcrumbItem><BreadcrumbItem active>{links[1].text}</BreadcrumbItem></> :
                <BreadcrumbItem active>{links[0].text}</BreadcrumbItem>
            }
        </Breadcrumb>
    )
}

export default BreadCrumb;