import { Link } from "react-router-dom";
import { splitNewlineTextJsx } from "../helpers";

const CableCard = ({ title, list_text, to, img }) => {
    return (
        <div className="shadow-sm border rounded-lg">
            <div className="p-4" style={{ position: "relative", minHeight: "250px" }}>
                <img src={`${process.env.PUBLIC_URL}/img/cables/${img}`} style={{
                    position: "absolute",
                    bottom: 0,
                    right: "16px",
                    zIndex: 0,
                    height: "230px"
                }} alt="" />
                <p className="mt-3 mb-2 font-weight-bold" style={{ zIndex: 1, position: "relative" }}>{splitNewlineTextJsx(title)}</p>
                <p className="mb-0 smaller w-75" style={{ zIndex: 1, position: "relative" }}>{splitNewlineTextJsx(list_text)}</p>
            </div>
            <div className="rounded-bottom bg-primary py-1">
                <Link to={`/cable/${to}`} className="no-decor">
                    <div className="container-fluid">
                        <div className="row text-white py-2">
                            <div className="col font-weight-bold h5 mb-0">
                                Explore
                            </div>
                            <div className="col-auto pr-2">
                                <svg height="24px" width="24px" viewBox="0 0 24 24">
                                    <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                                </svg>
                            </div>
                        </div>
                    </div>
                </Link>
            </div>
        </div >
    )
}

export default CableCard;