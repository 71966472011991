import { useEffect } from "react";
import BreadCrumb from "../Components/BreadCrumb";
import { animateCSS } from "../helpers";
// import { about_peoples } from "../data"

const About = ({ isSmall }) => {

    useEffect(() => {
        animateCSS('.breadcrumbWrapper', 'fadeInDown').then(() => {
            animateCSS('#animateText1', 'fadeInDown').then(() => {
                animateCSS('#animateText2', 'fadeInDown').then(() => {
                    animateCSS('#animateText3', 'fadeInDown').then(() => {
                        animateCSS('#animateBox', 'fadeInDown')
                        document.querySelector('#animateBox').classList.remove('hidden')
                    })
                    document.querySelector('#animateText3').classList.remove('hidden')
                })
                document.querySelector('#animateText2').classList.remove('hidden')
            });
            document.querySelector('#animateText1').classList.remove('hidden');
        })
        document.querySelector('.breadcrumbWrapper').classList.remove('hidden');
    }, []);

    return (
        <>
            <div className="container-fluid">
                <div className="row my-5 py-5">
                    <div className="col-12 col-lg-10 offset-lg-1">
                        <BreadCrumb isSmall={isSmall} links={[{ text: "About" }]} />
                        <div className="hidden animate__faster" id="animateText1">
                            <h3 className="text-primary">
                                Who are We?
                            </h3>
                            <p className="mb-2">We are pleased to introduce Parasavnath Trading Co. - a well-established name in the manufacturing industry with over 10 years of experience in supplying industrial products for various Power Plants, Industries, Buildings, Factories, Roads and Water Works.
                            </p>
                            <p className="mb-2">At Parasavnath Trading Co., we offer a wide range of products to cater to your business needs. Our product range includes Electrical Products, Earthing Products, Measurement Tools, Safety Products, Electronic Products, Power & Hand Tools, Welding & Cutting Products, Material Handling Products, Fire Safety Products, Pipes & Fittings, Hardware, Paint, and many more such products.
                            </p>
                            <p className="mb-2">In addition to our diverse product range, we offer customized solutions to cater to your specific business requirements. Our team of experts works closely with customers to understand their unique needs and provide tailored solutions to meet their specific requirements.
                            </p>
                            <p className="mb-2">We understand that timely delivery is critical to the success of your business, and that's why we have established a robust distribution network that ensures timely delivery of products to our customers. Our team of professionals is committed to meeting your needs and providing exceptional service, every time. We also offer competitive pricing, making our products affordable for businesses of all sizes. With our bulk order execution and production capabilities, we can offer cost-effective solutions that are tailored to your specific needs.
                            </p>
                            <p className="mb-5">In conclusion, we are confident that we can provide you with the industrial products and services that your business needs to succeed. Contact us today to learn more about our products and services, and let us help you take your business to the next level.
                            </p>
                        </div>
                        {/* <div className="hidden animate__faster" id="animateText2">
                            <h3 className="text-primary">
                                Our History
                            </h3>
                            <p className="mb-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                            </p>
                        </div> */}
                        {/* <div className="hidden animate__faster" id="animateText3">
                            <h3 className="text-primary">
                                Our People
                            </h3>
                            <p className="mb-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley
                            </p>
                        </div>
                        <div className="hidden animate__faster" id="animateBox">
                            <div className="row mb-5 pb-5">
                                {about_peoples.map(({ name, designation }, i) =>
                                    <div className="col-6 col-md-4 col-lg-3 mt-4" key={i}>
                                        <img className="w-100 img-fluid" src={`${process.env.PUBLIC_URL}/img/about_people/${i + 1}.png`} alt="" />
                                        <p className="mb-0 mt-2 font-weight-bold">{name}</p>
                                        <p className="mb-3 small">{designation}</p>
                                    </div>
                                )}
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default About;