import logo from '../img/logo-with-border.png';
import { Link } from 'react-router-dom';
import { Container, Row, Col, ListGroup } from 'reactstrap';
import { links } from '../data';
import { CONTACT_INFO } from '../data';

const Footer = () => {
  return (<Container fluid className="text-white pt-4 pb-4" style={{ background: "#026285" }}>
    <Row className='py-2'>
      <Col sm="12" lg={{ size: 4, offset: 1 }}>
        <Link to="/" className="d-flex align-items-center no-decor w-50" replace>
          <img src={logo} alt="" height="100px" />
          <span className="ml-3 h4 text-white">{CONTACT_INFO.company}</span>
        </Link>
      </Col>
      <Col sm="12" lg="3" className='pt-3'>
        <p className="h5 mb-3 mt-0 mt-lg-0">
          Quick Links
        </p>
        <ListGroup className="mt-2">
          {links.map(({ title, to }, i) => {
            return (
              <Link key={title} to={to} className="text-white pb-1 font-weight-light" replace>
                {title}
              </Link>
            );
          })}
        </ListGroup>
      </Col>
      <Col sm="12" lg="3" className='pt-3'>
        <p className="h5 mb-3 mt-0 mt-lg-0">
          Contact Information
        </p>
        <ListGroup className="mt-2 mb-3">
          <p className='mb-1 font-weight-bold widen'><u>OFFICE</u></p>
          <a href={CONTACT_INFO.address_href} target="_blank" className='text-white'>
            {CONTACT_INFO.address.map((data, i) =>
              <p key={i} className='mb-0 font-weight-light'>{data}</p>
            )}</a>
        </ListGroup>
        <ListGroup className="mt-1">
          <p className='mb-1 font-weight-bold widen'><u>PHONE</u></p>
          <a href={CONTACT_INFO.whatsapp_href} target="_blank" className='text-white mb-3 font-weight-light'>{CONTACT_INFO.phone}</a>
        </ListGroup>
        <ListGroup className="mt-1">
          <p className='mb-1 font-weight-bold widen'><u>EMAIL</u></p>
          <a href={`mailto:parasavnath.com`} className='text-white mb-3 font-weight-light'>{CONTACT_INFO.email}</a>
        </ListGroup>
      </Col>
    </Row>
  </Container>
  )
};

export default Footer;
