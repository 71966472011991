import { cables } from "../data";
import { Link, useParams } from "react-router-dom";
import BreadCrumb from "../Components/BreadCrumb";
import { splitNewlineTextJsx } from "../helpers";

const Cable = ({ isSmall }) => {

    const { cableLink } = useParams();
    const {
        title,
        text,
        text_full,
        img_full,
        features = [],
        more_text = [],
        description = [],
    } = cables[cableLink];

    return (
        <>
            <div className="container-fluid">
                <div className="row my-5 py-5">
                    <div className="col-12 col-lg-10 offset-lg-1">
                        <BreadCrumb isSmall={isSmall} links={[{ to: "/cables", text: "Cables" }, { text: title }]} />
                        <h3>
                            {title}
                        </h3>
                        <p className="mb-0">{text}</p>
                        <p className="mb-4">{text_full}</p>
                        <div className="text-center mb-5">
                            <img width={isSmall ? "100%" : "35%"} src={`${process.env.PUBLIC_URL}/img/cables/${img_full}`} alt="" />
                        </div>
                        <div className="row mb-5 font-weight-bold">
                            {features.map((feature, i) => <div className="col-6 col-lg-auto" key={i}>
                                <ul className="p-0 ml-3">
                                    <li>{feature}</li>
                                </ul>
                            </div>)}
                        </div>
                        {description.map(({ head, body }, i) =>
                            <div>
                                <p key={i} style={{ wordWrap: 'break-word' }}>
                                    <strong>{head}</strong>
                                    <br></br>
                                    {splitNewlineTextJsx(body)}
                                </p>
                            </div>
                        )}
                        {more_text.map((text, i) => <p key={i}>{text}</p>)}
                        <div className="text-center">
                            <Link to="/get-in-touch#enquiry" className={`${isSmall ? "w-100" : "w-25"} btn btn-primary btn-lg py-3 mt-5 mb-5`}>
                                Enquire Now
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cable;