import CableCard from "../Components/CableCard";
import { cables, CLIENTS_IMG_COUNT, PRODUCTS_IMG_COUNT, CLIENT_IMGS, HOME_SLIDER } from "../data";
import VisibilitySensor from 'react-visibility-sensor';
import { animateCSS } from '../helpers';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const delay = ms => new Promise(res => setTimeout(res, ms));

const Home = ({ isSmall }) => {

    const [carouselCounter, setCarouselCounter] = useState(0)

    const [cablesTextVisibility, setCablesTextVisibility] = useState(false);
    const [cablesBoxVisibility, setCablesBoxVisibility] = useState(false);

    const [productsTextVisibility, setProductsTextVisibility] = useState(false);
    const [productsBoxVisibility, setProductsBoxVisibility] = useState(false);

    const [clientsTextVisibility, setClientsTextVisibility] = useState(false);
    const [clientsBoxVisibility, setClientsBoxVisibility] = useState(false);

    useEffect(() => {
        animateCSS('#homeText', 'fadeInUp');
        document.querySelector('#homeText').classList.remove('hidden');
    }, [])

    useEffect(() => {
        const startCarousel = async () => {
            document.querySelector('#home-bg').style.backgroundImage = `url('${process.env.PUBLIC_URL}/img/home_bg/${HOME_SLIDER[carouselCounter].img}')`
            await delay(3500);
            setCarouselCounter((carouselCounter + 1) % HOME_SLIDER.length)
        }
        if (!isSmall)
            startCarousel()
    }, [carouselCounter]);

    useEffect(() => {
        if (cablesTextVisibility) {
            animateCSS('#cablesText', 'fadeInUp');
            document.querySelector('#cablesText').classList.remove('hidden');
        }
    }, [cablesTextVisibility]);

    useEffect(() => {
        if (productsTextVisibility) {
            animateCSS('#productsText', 'fadeInUp');
            document.querySelector('#productsText').classList.remove('hidden');
        }
    }, [productsTextVisibility]);

    useEffect(() => {
        if (productsBoxVisibility) {
            animateCSS('#productsBox', 'fadeInUp');
            document.querySelector('#productsBox').classList.remove('hidden');
        }
    }, [productsBoxVisibility]);

    useEffect(() => {
        if (clientsTextVisibility) {
            animateCSS('#clientsText', 'fadeInUp');
            document.querySelector('#clientsText').classList.remove('hidden');
        }
    }, [clientsTextVisibility]);

    useEffect(() => {
        if (clientsBoxVisibility) {
            animateCSS('#clientsBox', 'fadeInUp');
            document.querySelector('#clientsBox').classList.remove('hidden');
        }
    }, [clientsBoxVisibility]);

    return (
        <>
            <div id="home-bg" style={{ height: '80vh', marginTop: isSmall ? "-75px" : "-102px" }}>
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <div className="col-12 col-lg-10 offset-lg-1" style={{ position: 'relative' }}>
                            <div className="hidden animate__faster animate__delay-1s rounded p-0 p-lg-4 px-lg-5" style={{ position: "absolute", bottom: isSmall ? "25%" : "120px", marginTop: isSmall ? '2rem' : "", background: isSmall ? "None" : "#00000075" }} id="homeText">
                                <h2 className="font-weight-bold text-white">
                                    Parasavnath Trading Co.
                                </h2>
                                <p className="text-light mb-0">A house of Industrial Products
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row my-5 py-5">
                    <div className="col-12 col-lg-10 offset-lg-1">
                        <VisibilitySensor
                            partialVisibility
                            delayedCall
                            onChange={(isVisible) => {
                                if (isVisible && !cablesTextVisibility) {
                                    setCablesTextVisibility(true);
                                }
                            }}
                        >
                            <div className="hidden animate animate__delay-1s" id="cablesText">
                                <h3 className="text-primary">
                                    Our Cables
                                </h3>
                                <p className="mb-5">Our catalogue features a wide range of high-quality industrial cables which are ready to work in different environments.
                                </p>
                            </div>
                        </VisibilitySensor>
                        <VisibilitySensor
                            partialVisibility
                            delayedCall
                            onChange={(isVisible) => {
                                if (isVisible && !cablesBoxVisibility) {
                                    setCablesBoxVisibility(true);
                                }
                            }}
                        >
                            <div className="row">
                                {Object.entries(cables).map(([key, value], i) =>
                                    <div style={{ animationDelay: `${i * 200}ms` }} className={`col-12 col-md-4 col-lg-3 mt-4 animate__fast ${cablesBoxVisibility ? "animate__animated animate__fadeInUp" : "hidden"}`} key={i}>
                                        <CableCard {...value} to={key} />
                                    </div>
                                )}
                            </div>
                        </VisibilitySensor>
                    </div>
                </div>
                <div className="row py-5" style={{ background: "#F3F3F3" }}>
                    <div className="col-12 col-lg-10 offset-lg-1 my-5">
                        <VisibilitySensor
                            partialVisibility
                            delayedCall
                            onChange={(isVisible) => {
                                if (isVisible && !productsTextVisibility) {
                                    setProductsTextVisibility(true);
                                }
                            }}
                        >
                            <div id="productsText" className="hidden">
                                <h3 className="text-primary">
                                    Our Product Catalogue
                                </h3>
                                <p className="mb-5">Our catalogue features a wide range of high-quality industrial products from reputable manufacturers. Our catalogue has everything you need to keep your operations running smoothly.
                                </p>
                            </div>
                        </VisibilitySensor>
                        <VisibilitySensor
                            partialVisibility
                            delayedCall
                            onChange={(isVisible) => {
                                if (isVisible && !productsBoxVisibility) {
                                    setProductsBoxVisibility(true);
                                }
                            }}
                        >
                            <div className="pb-4 hidden animate__fast" id="productsBox">
                                <div className="row px-3">
                                    {Array.from({ length: PRODUCTS_IMG_COUNT }, (_, i) => i).map((x) => (
                                        <div className="col-6 col-lg-2 px-0" key={x}>
                                            <img className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/img/products/home/${x + 1}.png`} alt="" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </VisibilitySensor>
                        <div className="rounded-bottom bg-primary py-1">
                            <Link to={`/catalogue`} className="no-decor">
                                <div className="container-fluid">
                                    <div className="row text-white py-2">
                                        <div className="col font-weight-bold h5 mb-0">
                                            Explore Catalogue
                                        </div>
                                        <div className="col-auto pr-2">
                                            <svg height="24px" width="24px" viewBox="0 0 24 24">
                                                <path fill="currentColor" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="row my-5 py-5">
                    <div className="col-12 col-lg-10 offset-lg-1">
                        <VisibilitySensor
                            partialVisibility
                            delayedCall
                            onChange={(isVisible) => {
                                if (isVisible && !clientsTextVisibility) {
                                    setClientsTextVisibility(true);
                                }
                            }}
                        >
                            <div id="clientsText" className="hidden">
                                <h3 className="text-primary">
                                    Our Clients
                                </h3>
                                <p className="mb-5">We have served some leading names in the industry!
                                </p>
                            </div>
                        </VisibilitySensor>
                        <VisibilitySensor
                            partialVisibility
                            delayedCall
                            onChange={(isVisible) => {
                                if (isVisible && !clientsBoxVisibility) {
                                    setClientsBoxVisibility(true);
                                }
                            }}
                        >
                            <div className="pb-4 hidden animate__fast" id="clientsBox">
                                <div className="row px-3">
                                    {Array.from({ length: CLIENTS_IMG_COUNT }, (_, i) => i).map((x) => (
                                        <div className="col-6 col-lg-3 px-0" key={x}>
                                            <img className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/img/clients/${CLIENT_IMGS[x].img}`} alt="" />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </VisibilitySensor>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Home;