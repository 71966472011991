import { useState } from "react";
import { CONTACT_FORM_URL } from "../data";

const ContactForm = ({ isSmall }) => {
    const initState = {
        name: "",
        phone: "",
        email: "",
        subject: "",
        message: ""
    }
    const [submiting, setSubmiting] = useState(false);
    const [state, setState] = useState({ ...initState })
    const [message, setMessage] = useState(null)

    const onChange = (e) => {
        setMessage(null);
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();
        setSubmiting(true);
        await fetch(CONTACT_FORM_URL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(state)
        })
            .then(() => {
                setMessage("SUCCESS")
                setState({ ...initState });
                setSubmiting(false);
            })
            .catch(() => {
                setMessage("ERROR")
                setSubmiting(false);
            });
    }

    return (
        <form className="mt-5" onSubmit={(e) => submitForm(e)}>
            <div className="form-row">
                <div className="form-group col-lg-6">
                    <label htmlFor="name">Name*</label>
                    <input value={state.name} onChange={onChange} name="name" type="text" className="form-control form-control-lg" id="name" required placeholder="Enter Name" />
                </div>
                <div className="form-group col-lg-6">
                    <label htmlFor="phone">Phone*</label>
                    <input value={state.phone} onChange={onChange} type="tel" className="form-control form-control-lg" id="phone" name="phone" required placeholder="Enter Phone Number" />
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="email">Email</label>
                <input value={state.email} onChange={onChange} type="email" name="email" className="form-control form-control-lg" id="email" placeholder="Enter Email" />
            </div>
            <div className="form-group">
                <label htmlFor="subject">Subject</label>
                <input value={state.subject} onChange={onChange} type="text" className="form-control form-control-lg" name="subject" id="subject" placeholder="Enter Subject" />
            </div>
            <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea value={state.message} onChange={onChange} name="message" className="form-control form-control-lg" id="message" rows="5" placeholder="Enter Your Message"></textarea>
            </div>
            <button disabled={submiting} type="submit" className={`${isSmall ? "w-100" : "w-25"} btn btn-primary btn-lg py-3 mt-5 mb-5`}>Submit</button>
            {message === "SUCCESS" && <div className="alert alert-success" role="alert">
                Form submitted successfully!
            </div>}
            {message === "ERROR" && <div className="alert alert-danger" role="alert">
                There was an error submitting the form!
            </div>}
        </form>
    )
}

export default ContactForm;