import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import BreadCrumb from "../Components/BreadCrumb";
import { products } from "../data";
import { animateCSS } from "../helpers";

const Products = ({ isSmall }) => {

    const [activeTab, setActiveTab] = useState(0)

    useEffect(() => {
        animateCSS('.breadcrumbWrapper', 'fadeInDown').then(() => {
            animateCSS('#animateText', 'fadeInDown').then(
                () => document.querySelector('.animateBox').classList.remove('hidden')
            );
            document.querySelector('#animateText').classList.remove('hidden');
        })
        document.querySelector('.breadcrumbWrapper').classList.remove('hidden');
    }, []);

    return (
        <>
            <div className="container-fluid">
                <div className="row my-5 py-5">
                    <div className="col-12 col-lg-10 offset-lg-1">
                        <BreadCrumb isSmall={isSmall} links={[{ text: "Product Catalogue" }]} />
                        <div className="hidden animate__faster" id="animateText">
                            <h3 className="text-primary">
                                Product Catalogue
                            </h3>
                            <p className="mb-5">Our catalogue features a wide range of high-quality industrial products from reputable manufacturers, all conveniently organized and easy to navigate. Whether you're looking for tools, machinery, safety equipment, or anything in between, our catalogue has everything you need to keep your operations running smoothly. With detailed product descriptions and specifications, you can trust that you're making informed decisions when you shop with us.
                            </p>
                        </div>
                        <div className="hidden animateBox">
                            <Nav tabs>
                                {Object.entries(products).map(([key, _], i) =>
                                    <NavItem className={`border-bottom border-3 ${i === activeTab ? "border-primary" : ""}`} key={i}>
                                        <NavLink
                                            className={`p-3 mb-1 mx-1 rounded ${i === activeTab ? "bg-primary text-white" : "bg-white text-nav-unactive"}`}
                                            onClick={() => setActiveTab(i)}
                                        >
                                            {key}
                                        </NavLink>
                                    </NavItem>
                                )}
                                <NavItem className="border-bottom border-3 w-100" key="dummy"></NavItem>
                            </Nav>
                            <TabContent className="px-0 px-lg-4 py-4 py-lg-5" activeTab={activeTab}>
                                {Object.entries(products).map(([key, value], i) =>
                                    <TabPane tabId={i} key={i}>
                                        <h4 className="mb-4">{key}</h4>
                                        <Row className="text-secondary font-weight-light">
                                            {value.items.map((item, j) => <Col xs="12" lg="4" key={j}>
                                                <p className="mb-0">{item}</p>
                                            </Col>)}
                                        </Row>
                                        <div className="row mt-5 justify-content-center">
                                            {Array.from({ length: value.img_count }, (_, r) => r).map((x) => (
                                                <div className="col-6 col-lg-3 mb-3" key={x}>
                                                    <img className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/img/products/${value.img_folder}/${x + 1}.png`} alt="" />
                                                </div>
                                            ))}
                                        </div>
                                    </TabPane>
                                )}
                            </TabContent>
                            <div className="text-center">
                                <Link to="/get-in-touch#enquiry" className={`${isSmall ? "w-100" : "w-25"} btn btn-primary btn-lg py-3 mt-5 mb-5`}>
                                    Enquire Now
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Products;