import { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Footer from "./Components/Footer";
import NavBar from "./Components/NavBar";
import Home from "./Containers/Home";
import Cables from "./Containers/Cables";
import Cable from "./Containers/Cable";
import Clients from "./Containers/Clients";
import Products from "./Containers/Products";
import Contact from "./Containers/Contact";
import About from "./Containers/About";
import ReactGA from "react-ga4";

ReactGA.initialize("G-RCNM8TERJW");

const App = () => {

  const [isSmall, setIsSmall] = useState(_getIsSmall());

  useEffect(() => {
    const handleResize = () => {
      setIsSmall(_getIsSmall());
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Router>
      <NavBar isSmall={isSmall} />
      <div style={{ marginTop: isSmall ? 75 : 102, minHeight: '600px' }}>
        <Routes>
          <Route exact path='/' element={<Home isSmall={isSmall} />} />
          <Route exact path='/about' element={<About isSmall={isSmall} />} />
          <Route exact path='/cables' element={<Cables isSmall={isSmall} />} />
          <Route path='/cable/:cableLink' element={<Cable isSmall={isSmall} />} />
          <Route exact path='/catalogue' element={<Products isSmall={isSmall} />} />
          <Route exact path='/clients' element={<Clients isSmall={isSmall} />} />
          <Route exact path='/get-in-touch' element={<Contact isSmall={isSmall} />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  )
}

const _getIsSmall = () => {
  const { innerWidth: width } = window;
  return width < 768;
};


export default App;