import { useEffect } from "react";
import BreadCrumb from "../Components/BreadCrumb";
import { CLIENT_IMGS } from "../data";
// import { CLIENTS_IMG_COUNT } from "../data";
import { animateCSS } from "../helpers";

const Clients = ({ isSmall }) => {

    useEffect(() => {
        animateCSS('.breadcrumbWrapper', 'fadeInDown').then(() => {
            animateCSS('#animateText', 'fadeInDown').then(
                () => document.querySelector('.animateBox').classList.remove('hidden')
            );
            document.querySelector('#animateText').classList.remove('hidden');
        })
        document.querySelector('.breadcrumbWrapper').classList.remove('hidden');
    }, []);

    return (
        <>
            <div className="container-fluid">
                <div className="row my-5 py-5">
                    <div className="col-12 col-lg-10 offset-lg-1">
                        <BreadCrumb isSmall={isSmall} links={[{ text: "Clients" }]} />
                        <div className="hidden animate__faster" id="animateText">
                            <h3 className="text-primary">
                                Our Clients
                            </h3>
                            <p className="mb-5">We have served some leading names in the industry!
                            </p>
                        </div>
                        <div className="row px-3 hidden animateBox">
                            {Array.isArray(CLIENT_IMGS) && CLIENT_IMGS.map(client => {
                                return <div className="col-6 col-lg-3 mb-4 py-3" style={{ background: "#F3F3F3" }} key={client.img}>
                                    <img className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/img/clients/${client.img}`} alt="" />
                                </div>
                            })}
                            {/* {Array.from({ length: CLIENT_IMGS.length }, (_, i) => i).map((x) => (
                                <div className="col-6 col-lg-3 mb-4 py-3" style={{ background: "#F3F3F3" }} key={x}>
                                    <img className="img-fluid w-100" src={`${process.env.PUBLIC_URL}/img/clients/${CLIENT_IMGS.x}.jpeg`} alt="" />
                                </div>
                            ))} */}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Clients;