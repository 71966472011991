import CableCard from "../Components/CableCard";
import { cables } from "../data";
import BreadCrumb from "../Components/BreadCrumb";
import { animateCSS } from "../helpers";
import { useEffect } from "react";

const Cables = ({ isSmall }) => {

    useEffect(() => {
        animateCSS('.breadcrumbWrapper', 'fadeInDown').then(() => {
            animateCSS('#animateText', 'fadeInDown').then(
                () => document.querySelector('.animateBox').classList.remove('hidden')
            );
            document.querySelector('#animateText').classList.remove('hidden');
        })
        document.querySelector('.breadcrumbWrapper').classList.remove('hidden');
    }, []);

    return (
        <>
            <div className="container-fluid">
                <div className="row my-5 py-5">
                    <div className="col-12 col-lg-10 offset-lg-1">
                        <BreadCrumb isSmall={isSmall} links={[{ text: "Cables" }]} />
                        <div className="hidden animate__faster" id="animateText">
                            <h3 className="text-primary">
                                Our Cables
                            </h3>
                            <p className="mb-5">At Parasavnath Trading Co., we take pride in delivering an extensive selection of exceptional products to our valued customers. As a leading industry player, we set ourselves apart by offering specialized cables, including fire survival, zero halogen, and braided cables, tailored to meet specific applications. Our commitment to global quality standards and customer satisfaction ensures that every wire and cable we manufacture is engineered to precise specifications. Join our distinguished clientele today and experience the unparalleled reliability and performance of our products.
                            </p>
                        </div>
                        <div className="row hidden animateBox">
                            {Object.entries(cables).map(([key, value], i) =>
                                <div className="col-12 col-md-4 col-lg-3 mt-4" key={i}>
                                    <CableCard {...value} to={key} />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Cables;