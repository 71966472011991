import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/logo.png';
import { Collapse, Navbar, Nav, NavItem, Button } from 'reactstrap';
import { links } from '../data';
import { animateCSS } from '../helpers';
import { useLocation } from 'react-router-dom';

const NavBar = ({ isSmall }) => {

  const location = useLocation()
  const [collapsed, setCollapsed] = useState(true);

  useEffect(() => {
    animateCSS('#navbar', 'fadeInDown');
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [location]);

  return (
    <>
      <Navbar
        className="shadow py-2 py-lg-3 px-lg-5 animate__fast"
        color="white"
        expand="lg"
        fixed="top"
        light
        id='navbar'
      >
        <Link to="/" className="navbar-brand d-flex align-items-center" replace>
          <img src={logo} alt="" height={isSmall ? "50px" : "60px"} />
          <span className={`ml-3 font-weight-bold text-dark ${isSmall ? "small" : ""}`}>Parasavnath Trading Co.</span>
        </Link>
        <Button
          color="transparent"
          className="px-0 d-block d-lg-none"
          onClick={() => setCollapsed(!collapsed)}
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="#0F61AB">
            {collapsed ? <path d="M3,6H21V8H3V6M3,11H21V13H3V11M3,16H21V18H3V16Z" /> :
              <path d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z" />}
          </svg>
        </Button>
        <Collapse isOpen={!collapsed} navbar>
          <Nav className="ml-auto my-2 my-lg-0" navbar>
            {links.map(({ title, to }, i) => {
              return (
                <NavItem
                  key={title}
                  className={`d-flex align-items-center p-1 h5 my-2 my-lg-0 mb-0 ${i + 1 === links.length ? "" : "mr-5"}`}
                >
                  <Link to={to} replace>
                    {title}
                  </Link>
                </NavItem>
              );
            })}
          </Nav>
        </Collapse>
      </Navbar>
    </>
  );
};

export default NavBar;
