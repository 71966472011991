import { useState } from "react";
import { enquiry_form_options, ENQUIRY_FORM_URL, products } from "../data";

const EnquiryForm = ({ isSmall }) => {

    const initState = {
        category: "",
        product: "",
        toc: "",
        site_address: "",
        site_state: "",
        site_country: "",
        delivery_address: "",
        delivery_state: "",
        delivery_country: "",
        quantity: "",
        subject: "",
        message: ""
    }
    const [submiting, setSubmiting] = useState(false);
    const [state, setState] = useState({ ...initState })
    const [message, setMessage] = useState(null)

    const onChange = (e) => {
        setMessage(null);
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const submitForm = async (e) => {
        e.preventDefault();
        setSubmiting(true);
        await fetch(ENQUIRY_FORM_URL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(state)
        })
            .then(() => {
                setMessage("SUCCESS")
                setState({ ...initState });
                setSubmiting(false);
            })
            .catch(() => {
                setMessage("ERROR")
                setSubmiting(false);
            });
    }

    return (
        <form className="mt-5" onSubmit={(e) => submitForm(e)}>
            <div className="form-group">
                <label htmlFor="category">Category*</label>
                <select onChange={onChange} required id="category" name="category" className={`custom-select custom-select-lg ${state.category === "" ? "text-nav-unactive" : ""}`} defaultValue="">
                    <option disabled value="">Choose Category</option>
                    {Object.entries(products).map(([key, _], i) =>
                        <option key={i} value={key}>{key}</option>
                    )}
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="product">Product*</label>
                <select required onChange={onChange} id="product" name="product" className={`custom-select custom-select-lg ${state.product === "" ? "text-nav-unactive" : ""}`} defaultValue="">
                    <option disabled value="">{state.category === "" ? "Choose Category First" : "Choose Product"}</option>
                    {state.category && <>
                        {products[state.category].items.map((data, i) =>
                            <option key={i} value={data}>{data}</option>
                        )}
                    </>}
                </select>
            </div>
            <div className="form-group">
                <label htmlFor="toc">Type of Contact</label>
                <select onChange={onChange} name="toc" id="toc" className={`custom-select custom-select-lg ${state.toc === "" ? "text-nav-unactive" : ""}`} defaultValue="">
                    <option disabled value="">Choose Type of Contact</option>
                    {enquiry_form_options.toc.map((data, i) =>
                        <option key={i} value={data}>{data}</option>
                    )}
                </select>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="site_address">Site Location</label>
                    <input value={state.site_address} onChange={onChange} name="site_address" type="text" placeholder="Address" className="form-control form-control-lg" id="site_address" />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="site_state" className="d-none d-lg-block">&nbsp;</label>
                    <input value={state.site_state} onChange={onChange} name="site_state" type="text" placeholder="State" className="form-control form-control-lg" id="site_state" />
                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="site_country" className="d-none d-lg-block">&nbsp;</label>
                    <select onChange={onChange} name="site_country" id="site_country" className={`custom-select custom-select-lg ${state.site_country === "" ? "text-nav-unactive" : ""}`} defaultValue="">
                        <option disabled value="">Country</option>
                        {enquiry_form_options.contries.map((data, i) =>
                            <option key={i} value={data}>{data}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="form-row">
                <div className="form-group col-md-6">
                    <label htmlFor="delivery_address">Delivery Location</label>
                    <input value={state.site_address} onChange={onChange} name="delivery_address" type="text" placeholder="Address" className="form-control form-control-lg" id="delivery_address" />
                </div>
                <div className="form-group col-md-4">
                    <label htmlFor="delivery_state" className="d-none d-lg-block">&nbsp;</label>
                    <input value={state.site_address} onChange={onChange} name="delivery_state" type="text" placeholder="State" className="form-control form-control-lg" id="delivery_state" />
                </div>
                <div className="form-group col-md-2">
                    <label htmlFor="delivery_country" className="d-none d-lg-block">&nbsp;</label>
                    <select onChange={onChange} name="delivery_country" id="delivery_country" className={`custom-select custom-select-lg ${state.delivery_country === "" ? "text-nav-unactive" : ""}`} defaultValue="">
                        <option disabled value="">Country</option>
                        {enquiry_form_options.contries.map((data, i) =>
                            <option key={i} value={data}>{data}</option>
                        )}
                    </select>
                </div>
            </div>
            <div className="form-group">
                <label htmlFor="quantity">Quantity</label>
                <input value={state.quantity} onChange={onChange} name="quantity" type="text" className="form-control form-control-lg" id="quantity" placeholder="Enter Quantity" />
            </div>
            <div className="form-group">
                <label htmlFor="subject">Subject</label>
                <input value={state.subject} onChange={onChange} name="subject" type="text" className="form-control form-control-lg" id="subject" placeholder="Enter Subject" />
            </div>
            <div className="form-group">
                <label htmlFor="message">Message</label>
                <textarea value={state.message} onChange={onChange} name="message" className="form-control form-control-lg" placeholder="Enter Your Message" id="message" rows="5"></textarea>
            </div>
            <button disabled={submiting} type="submit" className={`${isSmall ? "w-100" : "w-25"} btn btn-primary btn-lg py-3 mt-5 mb-5`}>Submit</button>
            {message === "SUCCESS" && <div className="alert alert-success" role="alert">
                Form submitted successfully!
            </div>}
            {message === "ERROR" && <div className="alert alert-danger" role="alert">
                There was an error submitting the form!
            </div>}
        </form>
    )
}

export default EnquiryForm;