export const links = [
    {
        title: 'About',
        to: '/about',
    },
    {
        title: 'Cables',
        to: '/cables',
    },
    {
        title: 'Catalogue',
        to: '/catalogue',
    },
    {
        title: 'Clients',
        to: '/clients',
    },
    {
        title: 'Get in Touch',
        to: '/get-in-touch',
    },
];

export const CONTACT_INFO = {
    'company': 'Parasavnath Trading Co.',
    'address': [
        'A-208, North Ex Mall',
        'Sector 9, Rohini',
        'Delhi, India 110085'
    ],
    'address_href': 'https://goo.gl/maps/skHyZgefd1aPQpDs7',
    'phone': '+91-9910183682',
    'phone_href': '+919910183682',
    'whatsapp_href': 'https://wa.me/919910183682?text=Hi.+I+have+a+requirement+for+industrial+products+and+cables',
    'email': 'naren@parasavnath.com',
    'gst': '07AAFPJ6418A1Z5',
    'pan': 'AAFPJ6418A',
    'iso': '9001 - 2008'
}

export const HOME_SLIDER = [
    {
        'img': 'conductor_strand.jpeg',
    },
    {
        'img': 'conductor.jpeg',
    },
    {
        'img': 'core_laying_machine.jpeg',
    },
    {
        'img': 'core_laying.jpeg',
    }
]

export const CLIENTS_IMG_COUNT = 4
export const PRODUCTS_IMG_COUNT = 6
export const CONTACT_FORM_URL = 'https://reqres.in/api/articles'
export const ENQUIRY_FORM_URL = 'https://reqres.in/api/articles'

export const CLIENT_IMGS = [
    {
        'img': '2.jpeg'
    },
    {
        'img': '3.jpeg'
    },
    {
        'img': '4.jpeg'
    },
    {
        'img': '5.jpeg'
    },
    {
        'img': '6.png'
    },
    {
        'img': '7.png'
    },
    {
        'img': '8.jpeg'
    },
    {
        'img': '1.jpeg'
    }
]

export const about_peoples = [
    {
        "name": "Name 1",
        "designation": "Designation 1"
    },
    {
        "name": "Name 2",
        "designation": "Designation 2"
    },
    {
        "name": "Name 3",
        "designation": "Designation 3"
    },
    {
        "name": "Name 4",
        "designation": "Designation 4"
    }
]

// key of this dictionary is used for mapping data of individual cable it acts as url for each cable.
export const cables = {
    "pvc-insulated-armoured-cable": {
        title: "PVC Insulated \n Armoured Cable",
        list_text: "Cable Ducts\nCable Trays\nConduits\nUnderground",
        text: "Indoor & Outdoor Cable Ducts, Cable Trays, Conduits, Switching Systems, Industrial Power Units, Distribution Systems & Lighting",
        img: "pvc_insulated_2.png",
        img_full: "pvc_insulated.png",
        text_full: "Local distribution systems, Industrial and Commercial units for basic power & lighting purpose.",
        features: [
        ],
        description: [
            {
                "head": "Conductor",
                "body": "Aluminium / Annealed plain copper solid* / stranded conductor conform ot BS 6360 and IEC 60228. Class 2 (Circular / Sector shaped)"
            },
            {
                "head": "Insulation",
                "body": "PVC type Ill as per BS 7655: Section 3.1. and PVC type A as per IEC 60502-1"
            },
            {
                "head": "Assembly",
                "body": "Insulated conductors are laid up together, if necessary interstices may be filled with fillers."
            },
            {
                "head": "Fillers",
                "body": "Non-hygroscopic Poly propylene fillers are included between laid up cores wherever required. \nA separator tape of non-hygroscopic poly propylene material is applied over laid up cores wherever necessary."
            },
            {
                "head": "Bedding",
                "body": "Extruded PVC compatible with operating temperature."
            },
            {
                "head": "Armour",
                "body": "For Single Core - Aluminium round wire / flat Strep. \nFor Muticore - Galvanised Steel round wire / flat wire / tape."
            },
            {
                "head": "Outer Sheath",
                "body": "Extruded PVC / Special PVC compound such as Flame Retardant (FR), Flame Retardant Low Smoke (FRLS), Low Smoke Zero Halogen (LSOH) can be used for outer sheath to suit a variety of environment and fire risk conditions. Flamabillity test confirms to IEC 332 & Swidish chimeny. For installation where fire and associated problems such as emission of smoke and toxic fumes offer a serious potential threat, special LSF (Low smoke & fumes) compound can be provided. LSF compound is Halogen free (Flourine, Chlorine, Bromine) when tested as per BS 6425 (Pt 1) & IEC 60754 (Pt 1). The acid gas evolved during combustion is less than 0.5% by weight of material."
            },
            {
                "head": "Minimum Bending Radius",
                "body": "12 times the cable diameter"
            },
            {
                "head": "Admissible Pulling Force",
                "body": "Aluminium-30/sqmm"
            },
        ],
        more_text: [
        ],
    },
    "xlpe-insulated-armoured-cable": {
        title: "XLPE Insulated Armoured Cable",
        list_text: "Cable Ducts\nCable Trays\nConduits\nSwitching Systems\nIndustrial Power Units",
        text: "Indoor & Outdoor Cable Ducts, Cable Trays, Conduits, Switching Systems, Industrial Power Units, Distribution Systems & Lighting",
        img: "power_2.png",
        img_full: "power.png",
        text_full: "Local distribution systems, Industrial and Commercial units for basic power & lighting purpose.",
        features: [
        ],
        description: [
            {
                "head": "Conductor",
                "body": "Aluminium / Annealed plain copper solid* / stranded conductor conform to BS 6360 and IEC 60228, Class 2 (Circular / Sector shaped)."
            },
            {
                "head": "Insulation",
                "body": "Cross linked polyethylene (XLPE)",
            },
            {
                "head": "Assembly",
                "body": "Insulated conductors are laid up together, if necessary interstices may be filled with fillers.",
            },
            {
                "head": "Fillers",
                "body": "Non-hygroscopic Poly propylene fillers are included between laid up cores wherever required. \nA separator tape of non-hygroscopic poly propylene material is applied over laid up core wherever necessary.",
            },
            {
                "head": "Bedding",
                "body": "Bedding Extruded PVC compatible with operating temperature.",
            },
            {
                "head": "Armour",
                "body": "For Single Core - Aluminium round wire / flat Strep. \nFor Multicore - Galvanised Steel round wire / flat wire / tape",
            },
            {
                "head": "Outer Sheath",
                "body": "Extruded PVC / Special PVC compound such as Flame Retardant (FR), Flame Retardant Low Smoke (FRLS), Low Smoke Zero Halogen (LSOH) can be used for outer sheath to suit a variety of environment and fire risk conditions. Flamabillity test confirms to IEC 332 & Swidish chimeny. For installation where fire and associated problems such as emission of smoke and toxic fumes offer a serious potential threat, special LSF (Low smoke & fumes) compound can be provided. LSF compound is Halogen free (Flourine, Chlorine, Bromine) when tested as per BS 6425 (Pt 1) & IEC 60754 (Pt 1). The acid gas evolved during combustion is less than 0.5% by weight of material.",
            },
            {
                "head": "Minimum Bending Radius",
                "body": "12 times the cable diameter",
            },
            {
                "head": "Admissible Pulling Force",
                "body": "Aluminium-30/sqmm",
            }
        ],
        more_text: [
        ],
    },
    // "multi-core-cable": {
    //     title: "Multi Core Cable",
    //     list_text: "",
    //     text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //     img: "cable_img.png",
    //     img_full: "cable_img_full.png",
    //     text_full: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
    //     features: [
    //         "Feature 1",
    //         "Feature 2",
    //         "Feature 3",
    //         "Feature 4"
    //     ],
    //     more_text: [
    //     ],
    // },
    // "submersible-cable": {
    //     title: "Submersible Cable",
    //     list_text: "",
    //     text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //     img: "cable_img.png",
    //     img_full: "cable_img_full.png",
    //     text_full: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
    //     features: [
    //         "Feature 1",
    //         "Feature 2",
    //         "Feature 3",
    //         "Feature 4"
    //     ],
    //     more_text: [
    //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
    //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley"
    //     ],
    // },
    // "power-cable": {
    //     title: "Power Cable",
    //     list_text: "",
    //     text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    //     img: "cable_img.png",
    //     img_full: "cable_img_full.png",
    //     text_full: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
    //     features: [
    //         "Feature 1",
    //         "Feature 2",
    //         "Feature 3",
    //         "Feature 4"
    //     ],
    //     more_text: [
    //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley",
    //         "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley"
    //     ],
    // },
    "aerial-bunched-cable": {
        title: "Aerial Bunched Cable",
        list_text: "Outdoor Distribution",
        text: "",
        img: "aerial_bunched_2.png",
        img_full: "aerial_bunched.png",
        text_full: "Outdoor distribution in Rural or residential areas Offers cost effective safer and reliable cable for reticulation.",
        features: [
        ],
        description: [
            {
                "head": "Range",
                "body": "LT XLPE, AB cable or PE insulation",
            },
            {
                "head": "Insulation",
                "body": "Specially formulated for exposure to sunlight and outdoor application. LT XLPE or PE is loaded with carbon black MV cable insulated and screened cores are PVC sheathed.",
            },
            {
                "head": "Max Operating Temp",
                "body": "XLPE: Max 90°C , PVC or PE: Max 70°C",
            },
            {
                "head": "Construction",
                "body": "Insulated cores may be bundled together or laid up around high tensile insulated or bare messenger. If a messenger is provided; as the tension is taken by it, phase conductors can operate at maximum allowable conductor temperature.",
            },
            {
                "head": "Minimum Bending Radius",
                "body": "For LV 10 times & MV 15 times cable diameter",
            },
            {
                "head": "Solar Radiation",
                "body": "1000 W/ sq m",
            }
        ],
        more_text: [
        ],
    },
    "instrumentation-cable": {
        title: "Instrumentation Cable",
        list_text: "Process Control\nWet & Damp Areas",
        text: "",
        img: "instrumentation_2.png",
        img_full: "instrumentation.png",
        text_full: "Process control, equipment interconnection, typically in chemical and petrochemical locations. The unarmoured versions (Type 1) are generally used for indoor insulation and suitable for wet and damp areas.",
        features: [
        ],
        description: [
            {
                "head": "Conductor",
                "body": "0.5 sqmm to 2.5 sqmm of solid / stranded tinned / bare copper conductors.",
            },
            {
                "head": "Insulation",
                "body": "PVC / Polyethylene / XLPE / LSOH as per requirement.",
            },
            {
                "head": "Elements / Core",
                "body": "Pair / Triad / Quad, Color coded / number printed",
            },
            {
                "head": "Screening",
                "body": "Aluminium / Polyster screen over all (collective) screen (OAS), individual screen (IS) or both IS & OAS with ATC drain wire.",
            },
            {
                "head": "Brading",
                "body": "Available upon request.",
            },
            {
                "head": "Element Laying",
                "body": "Concentric formation or unit & group formation as per requirement."
            },
            {
                "head": "Armouring",
                "body": "Unarmoured / Galvanized Steel Wire / Flat wire armoured",
            },
            {
                "head": "Sheathing",
                "body": "PVC, FR PVC, FRLS, LSOH as per requirement.",
            },
            {
                "head": "Specification",
                "body": "EIL-6-52-46. Rev.05, BS: 5308 Part 1 & Part 2, BSC 143-0.75 (24/0.2 mm) OAS, IEC 60092-375, 376, BS EN 50288, VDE 0815, VG 95218, NEK 606 and customer's specifications.",
            }
        ],
        more_text: [
            "Introduction of microprocessor based / computerized instrumentation has demanded stringent quality requirements alongwith special electrical parameters for instrumentation cables.",
            "The cables used for instrumentation are designed and manufactured very meticulously. Parasavnath maintains high quality standards and follows stringent in-process quality control during manufacturing of instrumentation cables, meeting the design parameters of the customer."
        ],
    },
}

// key acts as products main category name
// img_folder is folder inside products category which is used for looping
export const products = {
    "Electrical Products": {
        items: [
            'LT Cables',
            'ELCB',
            'MS Conduit Pipes & Acc.',
            'HT Cables',
            'Main Switch',
            'G.I. & PVC Flexible Conduit Pipes',
            'Jointing Kit',
            'Change Over Switch',
            'HRC Fuse',
            'HBS Fuse',
            'Switches',
            'Termination Kit',
            'Sockets',
            'PVC Flexible Cables',
            'Composite CT/PT',
            'PVC Flexible Wires',
            'Distribution Board',
            'Distribution Panel',
            'Pin Insulator',
            'Submersible Cables',
            'Disc Insulator',
            'Rubber Cables',
            'Starters',
            'Drop Out Fuse',
            'Cable Lugs',
            'Cable Glands',
            'Electrical Rubber Mat',
            'Electrical Hand Gloves',
            'God Switch',
            'Stay Set/ Stay Wire',
            'MCB',
            'G.I. Conduit Pipes & Acc.',
            'Terminal Connector',
            'MCCB',
            'PVC Conduit Pipes & Acc.',
            'Street Light Pole',
            'Industrial Plug IP-44',
            'Industrial Socket IP-44',
            'HPSV & MH Flood Light',
            'HPSV & MH Street Light',
            'High Mast',
            'HPSV & MH Ballast',
            'HPSV & MH Lamps',
            'Ignitor',
            'Capacitor',
            'Tubes & Bulbs',
            'LED Flood Lights',
            'LED Street Lights',
            'Halogen Fittings',
            'Halogen Lamps',
            'Street Light Poles',
            'LED Bulbs',
            'Ceiling Fans',
            'Exhaust Fans',
            'PVC Tape',
            'Waterproof Tape',
            'HT Tape',
            'Crimping Tool',
            'MPCB',
            'Indication Lamp',
            'Lamp Holder',
            'MCB Enclosure',
            'Switch Board',
            'De Watering Pump',
            'Submersible Pump'
        ],
        img_folder: 'electrical',
        img_count: 11
    },
    "Power & Hand Tools": {
        items: [
            'Ring Spanner',
            'D-Spanner',
            'Slide Wrench',
            'Screw Driver',
            'Cutting Plier',
            'Nose Plier',
            'Monkey Plier',
            'Circlip Plier',
            'Wire Stripper Balpin Hammer',
            'Sledge Hammer',
            'Box Spanner',
            'Grinding Machine',
            'Drill Machines',
            'Pistol Grinder',
            'Straight Grinder',
            'Flexible Grinder',
            'Magnetic Drill Machine',
            'Grinding Wheel',
            'Cutting Wheel',
            'Marble Cutting Wheel',
            'Hole Saw Cutter',
            'Saw Blade',
            'Hydraulic Pipe Bender',
            'Pipe Cutting Machine',
            'Cable Cutter',
            'Ratchet Pipe Threader',
            'Bench Vice',
            'Chain Pipe Wrench',
            'Bolt Cutter',
            'Pipe Cutter',
            'Sheet Hole Cutter',
            'Pipe Cutting Machine',
            'Ratchet Drill Machine',
            'Pipe Hole Cutting Machine',
            'Tap Drill',
            'Clamp',
            'Grease Gun',
            'Rotary Barrel Pump',
            'Hydraulic Pipe Bender',
            'Hydraulic Motorised Compact Pipe Bender',
            'Hydraulic Bar and Strip Bender',
            'Tube Roller Bender',
            'Hydraulic Testing Pump',
            'Marble Cutter',
            'Allen Keys',
            'Centre Punch',
            'Socket Spanner',
            'Pipe Wrench',
            'Pipe Vice',
            'Tool Box'
        ],
        img_folder: 'power',
        img_count: 10
    },
    "Earthing Products": {
        items: [
            'G.I. Strips',
            'Copper Strips',
            'G.I. Pipe Electrodes',
            'G.I. Plate Electrodes',
            'Aluminium Strips',
            'G.I. Earthing Wire',
            'G.I. Earthing Rod',
            'C.I. Chamber Cover',
            'Funnel',
            'Wire Mesh',
            'Lightening Test Link',
            'Lighting Arrester',
            'G.I. Clamp',
            'Cable Tray',
            'Cable Tray Assessories',
            'Coal',
            'Salt'
        ],
        img_folder: 'earthing',
        img_count: 4
    },
    "Material Handling Products": {
        items: [
            'Wire Ropes',
            'Wire Rope Slings',
            'Wire Rope Pulley',
            'Wire Rope Clamps',
            'D-Shackles',
            'Tun Buckle',
            'P.P. Ropes',
            'Manilla Ropes',
            'Manilla Rope Pulley',
            'Chain Pulley Blocks',
            'Polyester / Nylon Belts',
            'Pulling & Lifting Machines',
            'Pallet Truck',
            'Polyster Slings',
            'Hand Winch Machines',
            'Electric Winch Machines',
            'Hydraulic Jack',
            'Mechanical Screw Jack',
            'Hook Chuck',
            'Bottle Jack',
            'Hydraulic Trolly Jack Tripod Stand'
        ],
        img_folder: 'handling',
        img_count: 8
    },
    "Fire Safety Products": {
        items: [
            'Powder Based Fire Extinguishers',
            'Water & Foam Based Fire Extinguishers',
            'Carbon Dioxide Based Fire Extinguishers',
            'Clean Agent Based Fire Extinguishers',
            'Automatic Modular Fire Extinguishers',
            'Trolley Mounted Foam Based Fire Extinguishers',
            'Bucket',
            'Bucket Stand',
            'Canvas Hose Pipe',
            'Fire Alarm System Smoke Alarm System',
            'CO, Alarm System',
            'Fire Blanket',
            'Fire Safety Chart',
            'Fire Exit Equipments',
            'Emergency Lighting & Signs',
            'All Fire Safety Equipments Accessories'
        ],
        img_folder: 'fire',
        img_count: 4
    },
    "Safety Products": {
        items: [
            'Safety Helmet',
            'Full Body Harness',
            'Safety Shoes',
            'Gumboot',
            'Raincoat',
            'Safety Net',
            'Barrication Tapes',
            'Barrication Net',
            'Barrication Boards',
            'Cotton Hand Gloves',
            'Leather Hand Gloves',
            'Rubber Hand Gloves',
            'Nose Mask',
            'Safety Jacket',
            'Safety Goggles',
            'Safety Cones',
            'Rope Lanyard Energy Absorber',
            'Anchorage Webbing Sling',
            'Anchorage Wire Rope Sling',
            'Fall Arrestor',
            'Anchorage Line System',
            'Retractable Fall Arrestor Block',
            'Self Contained Safety Kit',
            'Boiler Suit',
            'Earplug',
            'Ear Muff',
            'Stretcher',
            'First Aid Kit'
        ],
        img_folder: 'safety',
        img_count: 12
    },
    "Measurement Tools": {
        items: [
            'Analog Megger',
            'Lux meter',
            'Sound Meter',
            'Digital Megger',
            'Digital Multimeter',
            'Light Meter',
            'Clamp Meter',
            'Depth Gauge',
            'Speed Gun',
            'Bore Gauge',
            'Vernier Caliper',
            'Filler Gauge',
            'Multi Gas Detector',
            'Taper Gauge',
            'Tong Tester',
            'Torque Wrench',
            'Digital Earth Tester',
            'Digital Caliper',
            'Pressure Gauge',
            'Dial Indicator',
            'Spirit Level',
            'Compound Gauge',
            'Micro Meter',
            'Test Indicator',
            'Microscope',
            'ELCB Tester',
            'Ultrasonic Thickness'
        ],
        img_folder: 'measurement',
        img_count: 7
    }
}

export const enquiry_form_options = {
    toc: [
        "1",
        "2",
        "3"
    ],
    contries: [
        "India",
        "UAE"
    ]
}
